import { AutoComplete, AutocompleteValue } from "@/types/autocomplete";

type Params = {
  filters: Array<AutoComplete>;
};

function sortByCreationTime(a, b) {
  const creationTimeA = a.dataset_creation_time;
  const creationTimeB = b.dataset_creation_time;
  if (!creationTimeA || !creationTimeB) return 0;
  return creationTimeA > creationTimeB ? -1 : creationTimeA < creationTimeB ? 1 : 0;
}

function sortByPriority(a, b) {
  const order = [undefined, "individual_save_accounts", "individual_accounts"];
  const orderA = order.indexOf(a.dataset_type);
  const orderB = order.indexOf(b.dataset_type);
  return orderA < orderB ? -1 : orderA > orderB ? 1 : 0;
}

export function getDatasetsAndSavedSearches({ filters }: Params): Array<AutocompleteValue> {
  const combinedFilters = [] as Array<AutocompleteValue>;
  // datasets and saved searches are returned in a single object with an @id === "datasets" so we need to get those and then separate them
  const datasetsAndSavedSearches = filters.find((filter: AutoComplete) => filter.id === "datasets")?.autocomplete;

  // if no datasets or saved searches are returned, set the filters to an empty array
  if (!datasetsAndSavedSearches) {
    return [];
  }

  // loop through the datasets and saved searches and separate them
  const { datasets, savedSearches } = datasetsAndSavedSearches.reduce(
    (acc: { datasets: Array<AutocompleteValue>; savedSearches: Array<AutocompleteValue> }, filter: AutocompleteValue) => {
      if (filter.dataset_type === "saved_search" || filter.dataset_type === "segment") acc.savedSearches.push(filter);
      else acc.datasets.push(filter);
      return acc;
    },
    {
      datasets: [],
      savedSearches: [],
    },
  );

  const { filteredDatasets, customDatasets } = datasets.reduce(
    (acc: { filteredDatasets: Array<AutocompleteValue>; customDatasets: Array<AutocompleteValue> }, curr: AutocompleteValue) => {
      if (curr.dataset_type === "self_serve") acc.customDatasets.push(curr);
      else acc.filteredDatasets.push(curr);
      return acc;
    },
    {
      filteredDatasets: [],
      customDatasets: [],
    },
  );

  if (savedSearches.length) {
    combinedFilters.push({ label: "Saved Search", type: "heading" });
    combinedFilters.push(...savedSearches.sort(sortByCreationTime));
  }
  if (datasets.length) {
    combinedFilters.push({ label: "Saved Dataset", type: "heading" });
    combinedFilters.push(...filteredDatasets.sort(sortByPriority));
    combinedFilters.push(...customDatasets.sort(sortByCreationTime));
  }
  return combinedFilters;
}

/** Used to define whether to perform dataset/segment/self_serve specific actions */
export const isDatasetOrSegment = (dataset: AutocompleteValue | undefined): boolean => {
  if (!dataset) return false;
  switch (dataset?.dataset_type) {
    case "self_serve":
    case "saved_search":
    case "segment":
      return true;
    default:
      return false;
  }
};
