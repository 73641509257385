import Landing from "@/pages/landing.vue";
import { Rights } from "@/shared/user";
import utils from "@/shared/utils";
import { RouteRecord, createRouter, createWebHistory } from "vue-router";
import store from "./store";
import { useDiscover } from "@/stores/discover/store";

// IMPORTANT NOTE:
// routes with "-old" suffix on name provide backward compatibility, redirecting to previous routes

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "",
      name: "landing",
      component: Landing,
      meta: {
        title: "Enlyft Modeling UI",
        resetStates: ["segment"],
      },
    },
    {
      path: "/signup",
      component: () => import(/* webpackChunkName: "signup_index" */ "./pages/signUp/index.vue"),
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "signupverify" */ "./pages/signUp/verify.vue"),
          name: "verify",
          meta: {
            title: "Enlyft - Confirm Your Account",
            layout: "full",
            sidebar: "mainSidebar",
          },
        },
      ],
    },
    {
      path: "/insights",
      component: () => import(/* webpackChunkName: "insights_index" */ "./pages/insights/index.vue"),
      meta: {
        title: "Enlyft - Insights",
        requiresAuth: true,
        layout: "full",
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "insights_overview"*/ "./pages/insights/tabs/overview/overview.vue"),
          name: "insights",
          meta: {
            title: "Enlyft - Insights: Overview",
            requiresAuth: true,
            layout: "full",
            sidebar: "mainSidebar",
          },
        },

        {
          path: "technologies",
          component: () => import(/* webpackChunkName: "insights_tech"*/ "./pages/insights/tabs/technologies/technologies.vue"),
          name: "insights-technologies",
          meta: {
            title: "Enlyft - Insights: Technologies",
            requiresAuth: true,
            layout: "full",
            sidebar: "mainSidebar",
          },
        },
        {
          path: "people",
          component: () => import(/* webpackChunkName: "insights_overview"*/ "./pages/insights/tabs/people/people.vue"),
          name: "insights-people",
          meta: {
            title: "Enlyft - Insights: People",
            requiresAuth: true,
            layout: "full",
            sidebar: "mainSidebar",
          },
        },
        {
          path: "company-graph",
          component: () => import(/* webpackChunkName: "insights_overview"*/ "./pages/insights/tabs/companyGraph/companyGraph.vue"),
          name: "insights-company-graph",
          meta: {
            title: "Enlyft - Insights: Company Graph",
            requiresAuth: true,
            layout: "full",
            sidebar: "mainSidebar",
          },
        },
      ],
    },
    {
      path: "/measure",
      component: () => import(/* webpackChunkName: "measure_index"*/ "./pages/measure/index.vue"),
      meta: {
        sidebar: "mainSidebar",
        isManagerAndAbove: true,
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          name: "effectiveness",
          component: () => import(/* webpackChunkName: "effectiveness"*/ "./pages/measure/effectiveness.vue"),
          meta: {
            title: "Enlyft - Measure Effectiveness",
            requiresAuth: true,
            layout: "full",
          },
          children: [
            {
              path: "",
              name: "measure-root",
              redirect: "/measure/lift-curve",
              meta: {
                requiresAuth: true,
                layout: "full",
              },
              children: [],
            },
            {
              path: "performance-over-time",
              component: () => import(/* webpackChunkName: "performanceOverTime"*/ "./pages/measure/performanceOverTime.vue"),
              name: "performanceovertime",
              meta: {
                title: "Enlyft - Measure Performance Over Time",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "performanceovertime",
              component: () => import(/* webpackChunkName: "performanceOverTime"*/ "./pages/measure/performanceOverTime.vue"),
              name: "performanceovertime-old",
              redirect: "/measure/performance-over-time",
              children: [],
              meta: {
                title: "Enlyft - Measure Performance Over Time",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "comparison-with-models",
              component: () => import(/* webpackChunkName: "comparisonWithModels"*/ "./pages/measure/comparisonWithModels.vue"),
              name: "comparisonwithmodels",
              meta: {
                title: "Enlyft - Measure Comparison With Models",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "comparisonwithmodels",
              component: () => import(/* webpackChunkName: "comparisonWithModels"*/ "./pages/measure/comparisonWithModels.vue"),
              name: "comparisonwithmodels-old",
              redirect: "/measure/comparison-with-models",
              children: [],
              meta: {
                title: "Enlyft - Measure Comparison With Models",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "recent-performance",
              component: () => import(/* webpackChunkName: "recentPerformance"*/ "./pages/measure/recentPerformance.vue"),
              name: "recentperformance",
              meta: {
                title: "Enlyft - Measure Recent Performance",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "recentperformance",
              component: () => import(/* webpackChunkName: "recentPerformance"*/ "./pages/measure/recentPerformance.vue"),
              name: "recentperformance-old",
              redirect: "/measure/recent-performance",
              children: [],
              meta: {
                title: "Enlyft - Measure Recent Performance",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "lift-curve",
              component: () => import(/* webpackChunkName: "liftCurve"*/ "./pages/measure/liftCurve.vue"),
              name: "liftcurve",
              meta: {
                title: "Enlyft - Lift curve",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "liftcurve",
              component: () => import(/* webpackChunkName: "liftCurve"*/ "./pages/measure/liftCurve.vue"),
              name: "liftcurve-old",
              redirect: "/measure/lift-curve",
              children: [],
              meta: {
                title: "Enlyft - Lift curve",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "buying-patterns",
              component: () => import(/* webpackChunkName: "buyingPatterns"*/ "./pages/measure/buyingPatterns.vue"),
              name: "buyingpatterns",
              meta: {
                title: "Enlyft - Buying patterns",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "buyingpatterns",
              component: () => import(/* webpackChunkName: "buyingPatterns"*/ "./pages/measure/buyingPatterns.vue"),
              name: "buyingpatterns-old",
              redirect: "/measure/buying-patterns",
              children: [],
              meta: {
                title: "Enlyft - Buying patterns",
                requiresAuth: true,
                layout: "full",
              },
            },
          ],
        },
      ],
    },
    {
      path: "/new-search",
      component: () => import(/* webpackChunkName: "new_search_index" */ "./pages/new_search/index.vue"),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "new_search_explore" */ `./pages/new_search/discover.vue`),
          meta: {
            requiresAuth: true,
            layout: "full",
          },
          children: [
            {
              path: "overview",
              component: () => import(/* webpackChunkName: "new_search_overview" */ "./pages/new_search/overview.vue"),
              name: "filteroverview",
              meta: {
                title: "Enlyft - Discover Accounts",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "accounts",
              component: () => import(/* webpackChunkName: "new_search_accounts" */ "./pages/new_search/accounts.vue"),
              name: "filteraccounts",
              meta: {
                title: "Enlyft - Accounts Overview",
                requiresAuth: true,
                layout: "full",
              },
            },
          ],
        },
      ],
    },
    {
      path: "/history",
      component: () => import(/* webpackChunkName: "engage_index" */ "./pages/history/index.vue"),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          name: "history-root",
          redirect: "/history/recent",
          children: [],
          meta: {
            title: "Enlyft - Recent Accounts",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "recent",
          component: () => import(/* webpackChunkName: "history_accounts_recent" */ "./pages/history/recent.vue"),
          name: "recent",
          meta: {
            title: "Enlyft - Recent Accounts",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "tagged",
          component: () => import(/* webpackChunkName: "history_accounts_tagged" */ "./pages/history/taggedaccounts.vue"),
          name: "tagged",
          meta: {
            title: "Enlyft - Tagged Accounts",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "saved-accounts",
          component: () => import(/* webpackChunkName: "purchased_target_accounts" */ "./pages/history/saved-accounts/savedAccountsController.vue"),
          name: "saveddatasets",
          meta: {
            title: "Enlyft - Saved Datasets",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "saveddatasets",
          component: () => import(/* webpackChunkName: "purchased_target_accounts" */ "./pages/history/saved-accounts/savedAccountsController.vue"),
          name: "saveddatasets-old",
          redirect: "/history/saved-accounts",
          children: [],
          meta: {
            title: "Enlyft - Saved Datasets",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "saved-contacts",
          component: () => import(/* webpackChunkName: "saved_contacts" */ "./pages/history/contacts/contactsController.vue"),
          name: "savedcontacts",
          meta: {
            title: "Enlyft - Saved Contacts",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "savedcontacts",
          component: () => import(/* webpackChunkName: "saved_contacts" */ "./pages/history/contacts/contactsController.vue"),
          name: "savedcontacts-old",
          redirect: "/history/saved-contacts",
          children: [],
          meta: {
            title: "Enlyft - Saved Contacts",
            requiresAuth: true,
            layout: "full",
          },
        },
      ],
    },
    {
      path: "/datasets",
      name: "datasetdetails",
      component: () => import(/* webpackChunkName: "dataset_details" */ "./pages/history/datasetdetails.vue"),
      meta: {
        title: "Enlyft - Saved Datasets",
        requiresAuth: true,
        layout: "full",
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
    },
    {
      path: "/saved-searches",
      component: () => import(/* webpackChunkName: "engage_accounts" */ "./pages/saved_searches/savedsearches.vue"),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          component: () =>
            import(
              /* webpackChunkName: "saved_searches_all" */
              `./pages/saved_searches/mysearches.vue`
            ),
          name: "mySearches",
          meta: {
            title: "Enlyft - My Saved Searches",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "allSearches",
          component: () => import(/* webpackChunkName: "s_mysegment" */ `./pages/saved_searches/allsearches.vue`),
          name: "allSearches",
          meta: {
            title: "Enlyft - All Saved Searches",
            requiresAuth: true,
            layout: "full",
            isManagerAndAbove: true,
          },
        },
      ],
    },
    {
      path: "/searches",
      component: () => import(/* webpackChunkName: "engage_accounts" */ `./pages/prospect/index.vue`),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          component: () =>
            import(
              /* webpackChunkName: "saved_searches_all" */
              `./pages/prospect/index.vue`
            ),
          name: "prospectList",
          meta: {
            title: "Enlyft - Saved Searches",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "accounts",
          component: () => import(/* webpackChunkName: "accountList" */ "./pages/prospect/accounts.vue"),
          name: "accounts",
          meta: {
            title: "Enlyft - Saved Search Accounts",
            requiresAuth: true,
            layout: "full",
          },
        },
      ],
    },
    {
      path: "/login",
      component: () => import(/* webpackChunkName: "login_index" */ "./pages/login/index.vue"),
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "login_email" */ "./pages/login/email.vue"),
          name: "loginEmail",
          meta: {
            title: "Enlyft - Login",
            resetStates: ["segment", "targetAccount"],
          },
        },
        {
          path: "error",
          component: () => import(/* webpackChunkName: "login_error" */ "./pages/login/error.vue"),
          name: "genericError",
          meta: {
            title: "Enlyft - Error",
            resetStates: ["segment", "targetAccount"],
          },
        },
        {
          path: "password",
          component: () => import(/* webpackChunkName: "login_password" */ "./pages/login/password.vue"),
          name: "loginPassword",
          meta: {
            title: "Enlyft - Password",
            resetStates: ["segment", "targetAccount"],
          },
        },
        {
          path: "reset",
          component: () => import(/* webpackChunkName: "login_reset" */ "./pages/login/reset.vue"),
          name: "loginReset",
          meta: {
            title: "Enlyft - Reset password",
            resetStates: ["segment", "targetAccount"],
          },
        },
        {
          path: "newpassword",
          component: () => import(/* webpackChunkName: "login_newpassword" */ "./pages/login/newpassword.vue"),
          name: "newPassword",
          meta: {
            title: "Enlyft - Create a new password",
            resetStates: ["segment", "targetAccount"],
          },
        },
        {
          path: "openid",
          component: () => import(/* webpackChunkName: "login_openid" */ "./pages/login/openid.vue"),
          name: "openId",
          meta: {
            title: "Enlyft - OpenID",
            resetStates: ["segment"],
          },
        },
        {
          path: "teamSelection",
          component: () => import(/* webpackChunkName: "team_selection" */ "./pages/login/teamSelection.vue"),
          name: "teamSelection",
          meta: {
            title: "Enlyft - Subscription Selection",
            requiresAuth: true,
            layout: "full",
            sidebar: "mainSidebar",
            resetStates: ["segment", "targetAccount"],
          },
        },
        {
          path: "/modelSelection",
          component: () => import(/* webpackChunkName: "model_selection" */ "./pages/model/modelSelection.vue"),
          name: "modelSelection",
          meta: {
            title: "Enlyft - Solution Selection",
            requiresAuth: true,
            layout: "full",
            sidebar: "mainSidebar",
            resetStates: ["segment", "targetAccount"],
          },
        },
      ],
    },
    {
      path: "/team",
      component: () => import(/* webpackChunkName: "team_index" */ "./pages/team/index.vue"),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
        isAdminOnly: true,
      },
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "team_member" */ "./pages/team/teamMembers.vue"),
          name: "teamMember",
          meta: {
            title: "Enlyft - Team Members",
            requiresAuth: true,
            sidebar: "mainSidebar",
            layout: "full",
          },
        },
        {
          path: "create",
          component: () => import(/* webpackChunkName: "team_member_assign" */ "./pages/team/createUser.vue"),
          name: "createUser",
          meta: {
            title: "Enlyft - Create or Modify team members",
            requiresAuth: true,
            sidebar: "mainSidebar",
            layout: "full",
          },
        },
      ],
    },
    {
      path: "/settings",
      component: () => import(/* webpackChunkName: "plan_index" */ "./pages/settings/index.vue"),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "plan" */ "./pages/settings/usageReports/index.vue"),
          name: "usagereports",
          meta: {
            title: "Enlyft - Usage Reports",
            requiresAuth: true,
            sidebar: "mainSidebar",
            layout: "full",
            isAdminOnly: true,
          },
          children: [
            {
              name: "usagereportsoverview",
              path: "overview",
              component: () => import(/* webpackChunkName: "usagereports" */ "./pages/settings/usageReports/overview.vue"),
            },
            {
              name: "usagereportshistory",
              path: "history",
              component: () => import(/* webpackChunkName: "usagereports" */ "./pages/settings/usageReports/history.vue"),
            },
          ],
        },
        {
          path: "profile",
          component: () => import(/* webpackChunkName: "profile" */ "./pages/settings/profile.vue"),
          name: "profile",
          meta: {
            title: "Enlyft - My Profile",
            requiresAuth: true,
            layout: "full",
            sidebar: "mainSidebar",
          },
        },
        {
          path: "plan",
          component: () => import(/* webpackChunkName: "plan" */ "./pages/settings/plan.vue"),
          name: "plan",
          meta: {
            title: "Enlyft - Plan Details",
            requiresAuth: true,
            sidebar: "mainSidebar",
            layout: "full",
          },
        },
        {
          path: "solutions",
          component: () => import(/* webpackChunkName: "plan" */ "./pages/settings/solutions/index.vue"),
          name: "solutions",
          meta: {
            title: "Enlyft - Solutions",
            requiresAuth: true,
            sidebar: "mainSidebar",
            layout: "full",
          },
        },
      ],
    },
    {
      path: "/segment",
      component: () => import(/* webpackChunkName: "segment_index" */ "./pages/new_search/discover.vue"),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "segment_explore" */ "./pages/new_search/discover.vue"),
          meta: {
            requiresAuth: true,
            layout: "full",
          },
          children: [
            {
              path: "segmentoverview",
              component: () => import(/* webpackChunkName: "segment_overview" */ "./pages/new_search/overview.vue"),
              // Seems to be required field in vue 3
              children: [],
              name: "segmentoverview",
              redirect: "/new-search/overview",
              meta: {
                title: "Enlyft - Segment Overview",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "segmentaccounts",
              component: () => import(/* webpackChunkName: "segment_accounts" */ "./pages/new_search/accounts.vue"),
              name: "segmentaccounts",
              // Seems to be required field in vue 3
              children: [],
              redirect: "/new-search/accounts",
              meta: {
                title: "Enlyft - Segment Accounts",
                requiresAuth: true,
                layout: "full",
              },
            },
          ],
        },
      ],
    },
    // this is to match UI navigation - routes previously from "/model-insights/model-setup/"
    {
      path: "/setup",
      component: () => import(/* webpackChunkName: "model_setup" */ "./pages/discover/setup/setup.vue"),
      meta: {
        sidebar: "mainSidebar",
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          name: "setup-root",
          redirect: "/setup/buyer-persona",
          children: [],
          meta: {
            title: "Enlyft - Buying Signals Setup",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "historicwins",
          component: () => import(/* webpackChunkName: "setup_historic_wins" */ "./pages/discover/setup/historicwins.vue"),
          name: "historicwins",
          meta: {
            title: "Enlyft - Historic Wins",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "accountcredits",
          component: () => import(/* webpackChunkName: "setup_account_credits" */ "./pages/discover/setup/accountcredits.vue"),
          name: "accountcredits",
          meta: {
            title: "Enlyft - Account Export",
            requiresAuth: true,
            layout: "full",
            resetStates: ["segment"],
          },
        },
        {
          path: "targetaudience",
          component: () => import(/* webpackChunkName: "setup_target_audience" */ "./pages/discover/setup/targetaudience.vue"),
          name: "targetaudience",
          meta: {
            title: "Enlyft - Target Audience",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "customlinkedin",
          component: () => import(/* webpackChunkName: "setup_custom_linkedin" */ "./pages/discover/setup/customlinkedin.vue"),
          name: "customlinkedin",
          meta: {
            title: "Enlyft - Custom LinkedIn",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "uploadlist",
          component: () => import(/* webpackChunkName: "uploaded_list_page" */ "./pages/discover/setup/uploadlist.vue"),
          name: "uploadlist",
          meta: {
            title: "Enlyft - Uploaded List Page",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "enriched-accounts",
          component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/augmentaccounts.vue"),
          name: "augmentedaccounts",
          meta: {
            title: "Enlyft - Enriched Accounts",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "buying-signals",
          component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/pages/buying-signals/buyingSignalsSetup.vue"),
          name: "buying-signals-setup",
          meta: {
            title: "Enlyft - Buying Signals Setup",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "suppressed-accounts",
          component: () => import(/* webpackChunkName: "suppressed_accounts_page" */ "./pages/discover/setup/suppressaccounts.vue"),
          name: "suppressedaccounts",
          meta: {
            title: "Enlyft - Suppressed Accounts",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "highlighted-attributes",
          component: () => import(/* webpackChunkName: "setup_highlighted_attributes" */ "./pages/discover/setup/highlightedattributes.vue"),
          name: "highlightedattributes",
          meta: {
            title: "Enlyft - Highlighted Attributes",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "buyer-persona",
          component: () => import(/* webpackChunkName: "setup_buyer_persona" */ "./pages/discover/setup/buyerpersona.vue"),
          name: "buyerpersona",
          meta: {
            title: "Enlyft - Buyer Persona",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "emailGenerator",
          component: () => import(/* webpackChunkName: "setup_email_generator" */ "./pages/discover/setup/emailGenerator.vue"),
          name: "emailGenerator",
          meta: {
            title: "Enlyft - Outreach Approach",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "intent-setup",
          meta: {
            layout: "full",
          },
          component: () => import(/* webpackChunkName: "setup_intent" */ "./pages/setup/intent/index.vue"),
          children: [
            {
              path: "",
              component: () => import(/* webpackChunkName: "setup_intent_getstarted" */ "./pages/setup/intent/getstarted.vue"),
              name: "getstarted",
              meta: {
                title: "Enlyft - Intent",
                requiresAuth: true,
                layout: "full",
                requiresIntent: false,
              },
            },
            {
              path: "website",
              component: () => import(/* webpackChunkName: "setup_intent_website" */ "./pages/setup/intent/website.vue"),
              name: "website",
              meta: {
                title: "Enlyft - Confirm your company website",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "about",
              component: () => import(/* webpackChunkName: "setup_intent_about" */ "./pages/setup/intent/about.vue"),
              name: "about",
              meta: {
                title: "Enlyft - Tell us more about yourself",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "product",
              component: () => import(/* webpackChunkName: "setup_intent_product" */ "./pages/setup/intent/product.vue"),
              name: "product",
              meta: {
                title: "Enlyft - Confirm your company website",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "competitors",
              component: () => import(/* webpackChunkName: "setup_intent_competitors" */ "./pages/setup/intent/competitors.vue"),
              name: "competitors",
              meta: {
                title: "Enlyft - Tell us about similar solutions",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "categories",
              component: () => import(/* webpackChunkName: "setup_intent_categories" */ "./pages/setup/intent/categories.vue"),
              name: "categories",
              meta: {
                title: "Enlyft - Tell us about your product category",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "seedtopics",
              component: () => import(/* webpackChunkName: "setup_intent_seedtopics" */ "./pages/setup/intent/seedtopics.vue"),
              name: "seedtopics",
              meta: {
                title: "Enlyft - Suggested Intent Topics",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "optimize",
              component: () => import(/* webpackChunkName: "setup_intent_elasticity" */ "./pages/setup/intent/elasticity.vue"),
              name: "optimize",
              meta: {
                title: "Enlyft - Tell us about your use case",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "suggestedtopics",
              component: () => import(/* webpackChunkName: "setup_intent_suggestedtopics" */ "./pages/setup/intent/suggestedtopics.vue"),
              name: "suggestedtopics",
              meta: {
                title: "Enlyft - Intent topics",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
            {
              path: "intentsuccess",
              component: () => import(/* webpackChunkName: "setup_intent_success" */ "./pages/setup/intent/intentsuccess.vue"),
              name: "intentsuccess",
              meta: {
                title: "Enlyft - Intent setup success",
                requiresAuth: true,
                layout: "full",
                requiresIntent: true,
              },
            },
          ],
        },
        {
          path: "solution-outreach",
          component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/pages/solution-outreach/solutionOutreachSetup.vue"),
          name: "solution-outreach-setup",
          meta: {
            title: "Enlyft - Solution & Outreach Setup",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "icp",
          component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/pages/icp/icpSetup.vue"),
          name: "icp-setup",
          meta: {
            title: "Enlyft - Account Fit Setup",
            requiresAuth: true,
            layout: "full",
          },
        },
      ],
    },
    {
      path: "/model-insights",
      component: () => import(/* webpackChunkName: "discover_index" */ "./pages/discover/index.vue"),
      meta: {
        title: "Enlyft - Solution Insights",
        requiresAuth: true,
        layout: "full",
        isManagerAndAbove: true,
        sidebar: "mainSidebar",
        resetStates: ["segment"],
        isPaidUser: true,
      },
      children: [
        {
          path: "targetAccounts",
          redirect: "/new-search/accounts",
          children: [
            {
              path: "prospectList",
              redirect: "/new-search/accounts",
            },
            {
              path: "accountoverview",
              redirect: "/new-search/overview",
            },
          ],
        },
        {
          path: "purchasedtargetaccounts",
          redirect: "/history/saved-accounts",
        },
        {
          path: "savedsearches",
          redirect: "/saved-searches/",
        },
        {
          path: "",
          name: "modelinsights",
          component: () => import(/* webpackChunkName: "model_insights" */ "./pages/discover/insights/modelInsights.vue"),
          meta: {
            title: "Enlyft - Solution Insights",
            requiresAuth: true,
            layout: "full",
            isManagerAndAbove: true,
          },
          children: [],
        },
        {
          path: "modelinsights",
          redirect: "/model-insights/",
          name: "modelinsights-old",
          component: () => import(/* webpackChunkName: "model_insights" */ "./pages/discover/insights/modelInsights.vue"),
          meta: {
            title: "Enlyft - Solution Insights",
            requiresAuth: true,
            layout: "full",
            isManagerAndAbove: true,
          },
          children: [],
        },
        {
          path: "model-setup",
          component: () => import(/* webpackChunkName: "model_setup" */ "./pages/discover/setup/setup.vue"),
          meta: {
            requiresAuth: true,
            layout: "full",
            isManagerAndAbove: true,
          },
          children: [
            {
              path: "",
              component: () => import(/* webpackChunkName: "setup_historic_wins" */ "./pages/discover/setup/historicwins.vue"),
              name: "historicwins-old",
              redirect: "/setup/historicwins",
              children: [],
              meta: {
                title: "Enlyft - Historic Wins",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "accountcredits",
              component: () => import(/* webpackChunkName: "setup_account_credits" */ "./pages/discover/setup/accountcredits.vue"),
              name: "accountcredits-old",
              redirect: "/setup/accountcredits",
              children: [],
              meta: {
                title: "Enlyft - Account Export",
                requiresAuth: true,
                layout: "full",
                resetStates: ["segment"],
              },
            },
            {
              path: "targetaudience",
              component: () => import(/* webpackChunkName: "setup_target_audience" */ "./pages/discover/setup/targetaudience.vue"),
              name: "targetaudience-old",
              redirect: "/setup/targetaudience",
              children: [],
              meta: {
                title: "Enlyft - Target Audience",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "customlinkedin",
              component: () => import(/* webpackChunkName: "setup_custom_linkedin" */ "./pages/discover/setup/customlinkedin.vue"),
              name: "customlinkedin-old",
              redirect: "/setup/customlinkedin",
              children: [],
              meta: {
                title: "Enlyft - Custom LinkedIn",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "uploadlist",
              component: () => import(/* webpackChunkName: "uploaded_list_page" */ "./pages/discover/setup/uploadlist.vue"),
              name: "uploadlist-old",
              redirect: "/setup/uploadlist",
              children: [],
              meta: {
                title: "Enlyft - Uploaded List Page",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "enrichedaccounts",
              component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/augmentaccounts.vue"),
              name: "augmentedaccounts-old",
              redirect: "/setup/enriched-accounts",
              children: [],
              meta: {
                title: "Enlyft - Enriched Accounts",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "buying-signals-setup",
              component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/pages/buying-signals/buyingSignalsSetup.vue"),
              name: "buying-signals-setup-old",
              redirect: "/setup/buying-signals",
              children: [],
              meta: {
                title: "Enlyft - Buying Signals Setup",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "suppressedaccounts",
              component: () => import(/* webpackChunkName: "suppressed_accounts_page" */ "./pages/discover/setup/suppressaccounts.vue"),
              name: "suppressedaccounts-old",
              redirect: "/setup/suppressed-accounts",
              children: [],
              meta: {
                title: "Enlyft - Suppressed Accounts",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "highlightedattributes",
              component: () => import(/* webpackChunkName: "setup_highlighted_attributes" */ "./pages/discover/setup/highlightedattributes.vue"),
              name: "highlightedattributes-old",
              redirect: "/setup/highlighted-attributes",
              children: [],
              meta: {
                title: "Enlyft - Highlighted Attributes",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "buyerpersona",
              component: () => import(/* webpackChunkName: "setup_buyer_persona" */ "./pages/discover/setup/buyerpersona.vue"),
              name: "buyerpersona-old",
              redirect: "/setup/buyer-persona",
              children: [],
              meta: {
                title: "Enlyft - Buyer Persona",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "emailGenerator",
              component: () => import(/* webpackChunkName: "setup_email_generator" */ "./pages/discover/setup/emailGenerator.vue"),
              name: "emailGenerator-old",
              redirect: "/setup/emailGenerator",
              children: [],
              meta: {
                title: "Enlyft - Outreach Approach",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "intent-setup",
              meta: {
                layout: "full",
              },
              component: () => import(/* webpackChunkName: "setup_intent" */ "./pages/setup/intent/index.vue"),
              children: [
                {
                  path: "",
                  component: () => import(/* webpackChunkName: "setup_intent_getstarted" */ "./pages/setup/intent/getstarted.vue"),
                  name: "getstarted-old",
                  redirect: "/setup/intent-setup/",
                  children: [],
                  meta: {
                    title: "Enlyft - Intent",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: false,
                  },
                },
                {
                  path: "website",
                  component: () => import(/* webpackChunkName: "setup_intent_website" */ "./pages/setup/intent/website.vue"),
                  name: "website-old",
                  redirect: "/setup/intent-setup/website",
                  children: [],
                  meta: {
                    title: "Enlyft - Confirm your company website",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "about",
                  component: () => import(/* webpackChunkName: "setup_intent_about" */ "./pages/setup/intent/about.vue"),
                  name: "about-old",
                  redirect: "/setup/intent-setup/about",
                  children: [],
                  meta: {
                    title: "Enlyft - Tell us more about yourself",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "product",
                  component: () => import(/* webpackChunkName: "setup_intent_product" */ "./pages/setup/intent/product.vue"),
                  name: "product-old",
                  redirect: "/setup/intent-setup/product",
                  children: [],
                  meta: {
                    title: "Enlyft - Confirm your company website",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "competitors",
                  component: () => import(/* webpackChunkName: "setup_intent_competitors" */ "./pages/setup/intent/competitors.vue"),
                  name: "competitors-old",
                  redirect: "/setup/intent-setup/product",
                  children: [],
                  meta: {
                    title: "Enlyft - Tell us about similar solutions",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "categories",
                  component: () => import(/* webpackChunkName: "setup_intent_categories" */ "./pages/setup/intent/categories.vue"),
                  name: "categories-old",
                  redirect: "/setup/intent-setup/categories",
                  children: [],
                  meta: {
                    title: "Enlyft - Tell us about your product category",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "seedtopics",
                  component: () => import(/* webpackChunkName: "setup_intent_seedtopics" */ "./pages/setup/intent/seedtopics.vue"),
                  name: "seedtopics-old",
                  redirect: "/setup/intent-setup/seedtopics",
                  children: [],
                  meta: {
                    title: "Enlyft - Suggested Intent Topics",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "optimize",
                  component: () => import(/* webpackChunkName: "setup_intent_elasticity" */ "./pages/setup/intent/elasticity.vue"),
                  name: "optimize-old",
                  redirect: "/setup/intent-setup/optimize",
                  children: [],
                  meta: {
                    title: "Enlyft - Tell us about your use case",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "suggestedtopics",
                  component: () => import(/* webpackChunkName: "setup_intent_suggestedtopics" */ "./pages/setup/intent/suggestedtopics.vue"),
                  name: "suggestedtopics-old",
                  redirect: "/setup/intent-setup/suggestedtopics",
                  children: [],
                  meta: {
                    title: "Enlyft - Intent topics",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
                {
                  path: "intentsuccess",
                  component: () => import(/* webpackChunkName: "setup_intent_success" */ "./pages/setup/intent/intentsuccess.vue"),
                  name: "intentsuccess-old",
                  redirect: "/setup/intent-setup/intentsuccess",
                  children: [],
                  meta: {
                    title: "Enlyft - Intent setup success",
                    requiresAuth: true,
                    layout: "full",
                    requiresIntent: true,
                  },
                },
              ],
            },
            {
              path: "solution-outreach-setup",
              component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/pages/solution-outreach/solutionOutreachSetup.vue"),
              name: "solution-outreach-setup-old",
              redirect: "/setup/solution-outreach",
              children: [],
              meta: {
                title: "Enlyft - Solution & Outreach Setup",
                requiresAuth: true,
                layout: "full",
              },
            },
            {
              path: "icp-setup",
              component: () => import(/* webpackChunkName: "augmented_accounts_page" */ "./pages/discover/setup/pages/icp/icpSetup.vue"),
              name: "icp-setup-old",
              redirect: "/setup/icp",
              children: [],
              meta: {
                title: "Enlyft - Account Fit Setup",
                requiresAuth: true,
                layout: "full",
              },
            },
          ],
        },
        {
          path: "createeditattributes",
          component: () => import(/* webpackChunkName: "setup_highlighted_attributes" */ "./pages/discover/setup/create-editattribute.vue"),
          name: "createeditattributes",
          meta: {
            title: "Enlyft - Create/Edit Attributes",
            requiresAuth: true,
            layout: "full",
            isManagerAndAbove: true,
          },
        },
        {
          path: "uploadaccounts",
          component: () => import(/* webpackChunkName: "upload_accounts" */ "./pages/discover/setup/uploadaccounts.vue"),
          name: "uploadaccounts",
          meta: {
            title: "Enlyft - Upload Accounts",
            requiresAuth: true,
            layout: "full",
          },
        },
        {
          path: "editlinkedinsearch",
          component: () => import(/* webpackChunkName: "editlinkedinsearch" */ "./pages/discover/setup/editlinkedinsearch.vue"),
          name: "editlinkedinsearch",
          meta: {
            title: "Enlyft - Create/Edit Linkedin Search",
            requiresAuth: true,
            layout: "full",
            isManagerAndAbove: true,
          },
        },
      ],
    },
    {
      path: "/logout",
      component: () => import(/* webpackChunkName: "logout_index" */ "./pages/login/index.vue"),
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "logout" */ "./pages/login/logout.vue"),
          name: "logout",
          meta: {
            title: "Enlyft - logout",
            resetStates: ["segment", "targetAccount"],
          },
        },
      ],
    },
    {
      path: "/admin",
      redirect: "/admin/tenants",
      name: "admin",
      component: () => import(/* webpackChunkName: "admin_ui_index" */ "./pages/admin_ui/index.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        sidebar: "adminSidebar",
        isPaidUser: true,
        isEnlyftAdminOnly: true,
      },
      children: [
        {
          path: "tenants",
          component: () => import(/* webpackChunkName: "admin_ui_tenants_list" */ "./pages/admin_ui/tenantsList.vue"),
          name: "tenantsList",
          meta: {
            layout: "full",
            title: "Enlyft - View all tenants",
          },
        },
        {
          path: "tenants/new",
          component: () => import(/* webpackChunkName: "admin_ui_tenants_new" */ "./pages/admin_ui/createTenantTeamOffering.vue"),
          name: "tenantsNew",
          meta: {
            layout: "full",
            title: "Enlyft - Create a new Tenant",
          },
        },
        {
          path: "tenants/:tenantId",
          component: () => import(/* webpackChunkName: "admin_ui_tenants_info" */ "./pages/admin_ui/tenantInfo.vue"),
          name: "tenantInfo",
          meta: {
            layout: "full",
            title: "Enlyft - Tenant Info",
          },
        },
        {
          path: "tenants/:tenant_id/teams/:team_id",
          component: () => import(/* webpackChunkName: "admin_ui_tenant_team_info" */ "./pages/admin_ui/tenantTeamInfo.vue"),
          name: "tenantTeamInfo",
          meta: {
            layout: "full",
            title: "Enlyft - Subscription Info",
          },
        },
      ],
    },
    {
      path: "/payment",
      component: () => import(/* webpackChunkName: "payment_index" */ "./pages/payment/index.vue"),
      meta: {
        layout: "full",
        sidebar: "mainSidebar",
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "payment_subscription" */ "./pages/payment/subscription.vue"),
          name: "subscription",
          meta: {
            layout: "full",
            title: "Enlyft - Confirm Subscription",
            requiresAuth: true,
          },
        },
        {
          path: "stripe",
          component: () => import(/* webpackChunkName: "payment_stripe" */ "./pages/payment/stripe.vue"),
          name: "stripe",
          meta: {
            layout: "full",
            title: "Enlyft - Payment",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/integrations",
      component: () => import(/*...*/ "./pages/integrations/index.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        sidebar: "mainSidebar",
        isAdminOnly: true,
        isPaidUser: true,
      },
      children: [
        {
          path: "",
          component: () => import(/* webpackChunkName: "integrations_hub" */ "./pages/integrations/integrations_hub/index.vue"),
          name: "integrations_hub",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Integrations Hub",
          },
        },
        {
          path: "apitokens",
          component: () => import(/* webpackChunkName: "api_tokens" */ "./pages/integrations/ApiTokens.vue"),
          name: "api_tokens",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Api Tokens",
          },
        },
        {
          path: "pipedrive",
          component: () => import(/* webpackChunkName: "pipedrive" */ "./pages/integrations/integrations_hub/pipedrive.vue"),
          name: "pipedrive",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Pipedrive",
          },
        },
        {
          path: "contacts",
          component: () => import(/* webpackChunkName: "records" */ "./pages/integrations/integrations_hub/integrations_records.vue"),
          name: "integrations_records",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Configure record export",
          },
        },
        {
          path: "pipedrive-pipeline",
          component: () => import(/* webpackChunkName: "pipedrive" */ "./pages/integrations/integrations_hub/pipedrive_pipeline.vue"),
          name: "pipedrive-pipeline",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Pipedrive",
          },
        },
        {
          path: "dynamics_callback",
          component: () => import(/* webpackChunkName: "dynamics_callback" */ "./pages/integrations/integrations_hub/d365_callback.vue"),
          name: "dynamics_callback",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Dynamics",
          },
        },
        {
          path: "dynamics",
          component: () => import(/* webpackChunkName: "dynamics" */ "./pages/integrations/integrations_hub/d365.vue"),
          name: "dynamics",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Dynamics",
          },
        },
        {
          path: "salesforce_callback",
          component: () => import(/* webpackChunkName: "salesforce_callback" */ "./pages/integrations/integrations_hub/salesforce_callback.vue"),
          name: "salesforce_callback",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Salesforce",
          },
        },
        {
          path: "salesforce",
          component: () => import(/* webpackChunkName: "salesforce" */ "./pages/integrations/integrations_hub/salesforce.vue"),
          name: "salesforce",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Salesforce",
          },
        },
        {
          path: "hubspot_callback",
          component: () => import(/* webpackChunkName: "hubspot_callback" */ "./pages/integrations/integrations_hub/hubspot_callback.vue"),
          name: "hubspot_callback",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - HubSpot",
          },
        },
        {
          path: "outreach_callback",
          component: () => import(/* webpackChunkName: "outreach_callback" */ "./pages/integrations/integrations_hub/outreach_callback.vue"),
          name: "outreach_callback",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Outreach",
          },
        },
        {
          path: "offering",
          component: () => import(/* webpackChunkName: "integrations_offering" */ "./pages/integrations/integrations_hub/integrations_offering.vue"),
          name: "integrations_offering",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Select Solution",
            isCRMOnly: true,
          },
        },
        {
          path: "datapoints",
          component: () => import(/* webpackChunkName: "integrations_fields" */ "./pages/integrations/integrations_hub/integrations_fields.vue"),
          name: "integrations_fields",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Select Fields",
            isCRMOnly: true,
          },
        },
        {
          path: "contact_datapoints",
          component: () => import(/* webpackChunkName: "integrations_fields" */ "./pages/integrations/integrations_hub/integrations_contact_fields.vue"),
          name: "integrations_contact_fields",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Select Contact Fields",
            isCRMOnly: true,
          },
        },
        {
          path: "datasets",
          component: () => import(/* webpackChunkName: "integrations_fields" */ "./pages/integrations/integrations_hub/integrations_datasets.vue"),
          name: "integrations_datasets",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Export saved accounts",
            isCRMOnly: true,
          },
        },
        {
          path: "permission",
          component: () => import(/* webpackChunkName: "integrations_permission" */ "./pages/integrations/integrations_hub/integrations_permission.vue"),
          name: "integrations_permission",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Data Policy",
            isCRMOnly: true,
          },
        },
        {
          path: "success",
          component: () => import(/* webpackChunkName: "integrations_success" */ "./pages/integrations/integrations_hub/integrations_success.vue"),
          name: "integrations_success",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Integrations",
            isCRMOnly: true,
          },
        },
        {
          path: "mode",
          component: () => import(/* webpackChunkName: "integrations_mode" */ "./pages/integrations/integrations_hub/integrations_mode.vue"),
          name: "integrations_mode",
          meta: {
            layout: "full",
            requiresAuth: true,
            sidebar: "mainSidebar",
            title: "Enlyft - Integration Mode",
            isCRMOnly: true,
          },
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      name: "notfound",
      component: () => import(/* webpackChunkName: "notfound" */ "./pages/notfound.vue"),
      meta: {
        title: "Enlyft - Page Not Found",
      },
    },
    {
      path: "/error",
      name: "error",
      component: () => import(/* webpackChunkName: "error" */ "./pages/error.vue"),
      meta: {
        title: "Enlyft - Encountered an error",
        layout: "full",
        sidebar: "mainSidebar",
        resetStates: ["segment", "targetAccount"],
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import(/* webpackChunkName: "error" */ "./pages/dashboard/index.vue"),
      meta: {
        title: "Enlyft - Dashboard",
        layout: "full",
        sidebar: "mainSidebar",
      },
    },
  ],
});

// if store.appNeedLogin, go to route 'loginEmail'
let firstRoute: any = null;
let waitingForApp = true;
let waitingForAppTimedOut = false;
let appNeedsLogin = false;
store.subscribe((mutation) => {
  switch (mutation.type) {
    case "setAppReady":
      if (waitingForAppTimedOut) {
        console.log("app ready too late");
        return;
      }
      waitingForApp = false;
      const activeTeam = store.getters["team/activeTeam"];
      if (firstRoute) {
        console.log("app ready", firstRoute.to ? firstRoute.to.name : firstRoute);
        checkRoute(firstRoute.to, firstRoute.from, firstRoute.next);
      } else {
        console.log("app ready but no route");
      }
      try {
        if ((window as any).mixpanel) {
          const user = store.getters["login/getUser"];
          utils.setMixPanelUserProperties(user, activeTeam);
        }
      } catch (e) {
        console.error("Error initializing Mixpanel", e);
      }
      break;
    case "setAppNeedLogin":
      if (waitingForAppTimedOut) {
        console.log("login needed, too late");
        router.push({
          name: "loginEmail",
        });
      }
      waitingForApp = false;
      appNeedsLogin = !!mutation.payload;
      if (appNeedsLogin) {
        router.push({
          name: "loginEmail",
        });
      }
      break;
    default:
      break;
  }
});
// if after 12 seconds we haven't succesfully loaded the app, go to login page to start from scratch (no session resume)
setTimeout(() => {
  if (!waitingForApp) {
    return;
  }
  console.log("failed to resume session");
  waitingForAppTimedOut = true;
  store.dispatch("setAppNeedLogin", true);
}, 12 * 1000);

const checkRoute = async (to: any, from: any, next: any) => {
  const nearestWithTitle: RouteRecord | undefined = to.matched
    .slice()
    .reverse()
    .find((r: any) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    (document as any).title = nearestWithTitle.meta.title;
  }
  if (to.matched.some((record: any) => record.meta.resetStates)) {
    const states = to.meta.resetStates;
    if (Array.isArray(states) && states.length > 0) {
      states.forEach((state: string) => resetState(state));
    }
  }
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    const user = store.getters["login/getUser"];
    if (!user) {
      if (to.name === "insights") {
        next({
          path: "/login",
          query: {
            redirect: to.path,
            accountId: to.query.accountId,
            modelId: to.query.modelId,
            tld: to.query.tld,
          },
        });
        return;
      } else {
        const path = "/login";
        console.log("Routing to path : ", path, " .Redirect path: ", to.fullPath);
        // await to avoid racing condition with promise inside logout action
        await store.dispatch("login/logout");
        if (to.fullPath.includes("/teamSelection")) {
          next({
            path: path,
          });
        } else {
          next({
            path: path,
            query: {
              redirect: to.fullPath,
            },
          });
        }
        return;
      }
    } else {
      try {
        const activeTeam = store.getters["team/activeTeam"];
        const user = store.getters["login/getUser"];
        const featuresLimit = store.getters["team/featuresLimit"];
        const activeModel = store.getters["model/activeModel"];

        // if there is no subscription set redirect to selection page
        if (!activeTeam && !to.path.includes("teamSelection") && !from.path.includes("login")) {
          next({
            path: "/login/teamSelection",
            query: {
              redirect: to.fullPath,
            },
          });
          return;
        } else if (!to.path.includes("payment") && !to.path.includes("teamSelection") && activeTeam && activeTeam.payment_status) {
          const status = activeTeam.payment_status;
          if (status != "active" && !activeTeam.is_trial) {
            next({
              path: "/payment",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }
        let newModel = false;
        // if there is a modelId in query and its different from the active one, set it as active if its valid
        if (activeModel && to.query.modelId !== activeModel._id) {
          newModel = true;
        }
        // if there is no active model, sets the first as active
        if ((!activeModel && !from.path.includes("login") && !to.path.includes("teamSelection") && !to.path.includes("modelSelection")) || newModel) {
          let activeTeamModels = store.getters["model/getActiveTeamModels"];
          if (!activeTeamModels || !Array.isArray(activeTeamModels) || (Array.isArray(activeTeamModels) && !activeTeamModels.length)) {
            activeTeamModels = await store.dispatch("model/getTeamModels");
          }
          if (activeTeamModels && Array.isArray(activeTeamModels) && activeTeamModels.length) {
            const queryModelId = to.query.modelId;
            const queryModelFound = activeTeamModels.find((model) => model._id === queryModelId);
            if (!queryModelFound && queryModelId) {
              delete to.query.modelId;
            }
            if (!newModel) {
              const model = queryModelFound || activeTeamModels[0];
              // set first model as default if there is no active model and no model from query
              store.dispatch("model/setActiveModel", model);
            } else {
              // if there is a new model on query, sets it only if its found on team models
              if (queryModelFound) {
                store.dispatch("model/setActiveModel", queryModelFound);
              }
            }
          }
        }
        //Remove this hack once Beamer alert is updated
        if (to.query?.utm_campaign && to.query.utm_campaign === "New-accounts-surging-in-buying-intent") {
          next({
            name: to.name,
            query: {
              filter: "XQAAAAJKAAAAAAAAAABCKYqGE8gphNQFLlzfJNSAIJykU_PSuPt2BujGtCI4sPNCVWbwMtarWZsk4DnpAEvBchn_2xuLUObXUpF1nXoL2awUt7NV2MlLPVf--zd7gA",
            },
          });
          return;
        }

        if (to.path.includes("dashboard") && (activeTeam?.role === "sales" || !user.hasDashboardPage)) {
          next({
            name: "filteroverview",
            query: {
              redirect: to.path,
            },
          });
          return;
        }

        if (to.path.includes("visitortracker")) {
          const canVistrac = activeTeam && activeTeam.user_account_flags && activeTeam.user_account_flags.vistrac_enabled;
          if (!canVistrac) {
            next({
              name: "notfound-path",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }

        if (to.matched.some((record: any) => record.meta.isAdminOnly)) {
          const canSeeAdminOnly = activeTeam && activeTeam.role && (activeTeam.role === "admin" || activeTeam.role === "enlyft_cs");
          if (!canSeeAdminOnly) {
            next({
              name: "notfound-path",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }

        if (to.matched.some((record: any) => record.meta.isManagerAndAbove)) {
          const canSeeAdminManagerOnly = activeTeam && activeTeam.role && (activeTeam.role === "admin" || activeTeam.role === "enlyft_cs" || activeTeam.role === "viewer");
          if (!canSeeAdminManagerOnly) {
            next({
              name: "notfound-path",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }

        if (to.matched.some((record: any) => record.meta.isEnlyftAdminOnly)) {
          const canSeeEnlyftAdminOnly = user && user.is(Rights.EnlyftAdmin);
          if (!canSeeEnlyftAdminOnly) {
            next({
              name: "notfound-path",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }

        if (to.matched.some((record: any) => record.meta.isCRMOnly)) {
          const canCRM = !!(user && featuresLimit && user.canFeature(Rights.EnableCRM, featuresLimit));
          if (!canCRM) {
            next({
              name: "notfound-path",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }

        if (to.matched.some((record: any) => record.meta.isPaidUser)) {
          const isPaidUser = activeTeam && activeTeam.plan_name && activeTeam.plan_name.toLowerCase() !== "free plan";
          if (!isPaidUser) {
            next({
              name: "modelSelection",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }

        if (to.matched.some((record: any) => record.meta.requiresIntent)) {
          if (activeTeam.plan_name !== "enterprise") {
            const teamHasIntent = () => {
              const featuresLimit = store.getters["team/featuresLimit"];
              return featuresLimit && featuresLimit.intent;
            };
            if (!teamHasIntent()) {
              next({
                name: "getstarted",
                query: {
                  redirect: to.path,
                },
              });
              return;
            }
          } else {
            next({
              name: "notfound-path",
              query: {
                redirect: to.path,
              },
            });
            return;
          }
        }
      } catch (e) {
        console.error("Error getting subscription data from localstorage", e);
      }
      next();
      return;
    }
  } else {
    next();
    return;
  }
};

router.beforeEach((to, from, next) => {
  if (waitingForApp) {
    firstRoute = { to, from, next };
    return;
  }
  checkRoute(to, from, next);
});

router.afterEach((to, from) => {
  try {
    const envPath = process.env.VUE_APP_MODEL_API ? process.env.VUE_APP_MODEL_API.replace(/\//g, "") : "";
    const title = to && to.meta && to.meta.title ? to.meta.title : document.title;
    const path = to && to.path ? to.path : window.location.pathname;
    const referrer = from && from.path ? from.path : "/";
    const pageUrl = to.fullPath ? envPath + to.fullPath : window.location.href;

    (window as any).gtag("event", "page_view", {
      page_title: title,
      page_location: path,
      page_path: pageUrl,
    });
    (window as any).gtag("event", "PageView", {
      event_category: title,
      event_label: pageUrl,
    });
    if ((window as any).mixpanel && (window as any).mixpanel.app) {
      const user = store.getters["login/getUser"];
      const activeTeam = store.getters["team/activeTeam"];

      if (user) {
        (window as any).mixpanel.app.identify(user.id);
        utils.setMixPanelUserProperties(user, activeTeam);
        (window as any).mixpanel.app.track("PageView", {
          Title: title,
          Page: utils.getFormattedPath(path),
          From: utils.getFormattedPath(referrer),
          Source: "App",
        });
      }
    }
  } catch (e) {
    console.error("Error sending page details to analytics", e);
  }
});

router.onError((error) => {
  console.info("router error:", error.message);
  if (/loading(.*?)chunk (.*?) failed./i.test(error.message) && navigator.onLine) {
    store.dispatch("tryUpdateErrorRefresh");
  }
});

export default router;

function resetState(state: string): void {
  switch (state) {
    case "segment":
      store.dispatch("segment/resetSegmentState");
      break;
    case "discover":
      const discoverStore = useDiscover();
      discoverStore.$reset();
      break;

    case "targetAccount":
      store.dispatch("targetAccounts/resetTagetAccountsState");
      break;
  }
}
