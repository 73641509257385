import axios, { AxiosResponse } from "axios";
import utils from "@/shared/utils";
import { Exports } from "@/types/exports";
import { GENERIC_ERRORS } from "@/shared/constants/errorConstants";

/** Allows exporting contacts or saved accounts */
export async function exportToCRM({ entityType, integrationType, modelId, teamId, payload }: Exports.ExportParams): Promise<Exports.APIResponse> {
  try {
    const response = (await axios.post(`${utils.MODEL_API}v2/customer_products/${teamId}/${modelId}/${integrationType}/${entityType}/export/`, payload)) as AxiosResponse;
    if (response?.data?.status === "success") return response.data;
    if (response?.data?.status === "failure") throw new Error(response.data.message || GENERIC_ERRORS.EXPORT_ERROR);

    return response.data;
  } catch (error: any) {
    console.error("exportToCRM", error.message);
    throw new Error(error?.message);
  }
}

export async function updateHubspotContacts(teamId: string, modelId: string, body: any): Promise<object> {
  return new Promise((resolve, reject) => {
    axios
      .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/update/hubspot_contacts/", body)
      .then(utils.genericSuccessHandler(resolve, reject))
      .catch(utils.genericErrorHandler(resolve, reject));
  });
}
