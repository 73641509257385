<template>
  <component :is="layout">
    <router-view />
  </component>
  <div id="aria-live--content" aria-live="polite">{{ sharedStore.ariaLiveText }}</div>
  <div id="toasts">
    <div v-if="$store.state.updateExist && !$store.state.needRefreshBar" class="toast-mask" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast refreshtoast">
        <div class="modal-header update-header">
          <div class="header row col-md-12 pl-0">
            <div class="col-md-12">Update Available</div>
          </div>
        </div>

        <div class="row col-md-12 pr-0">
          <div class="col-md-9 col-sm-6 mt-3">We have been busy working on the system and have an update for you. Please click on the refresh button to get the update.</div>
          <div class="col-md-3 col-sm-6">
            <img src="./shared/icon-success-large.svg" class="alert-icon" alt="Success" />
          </div>
        </div>

        <div class="row col-md-12 mt-2 pr-0">
          <div class="col-md-5 mt-3">
            <button @click="dontRefresh" class="btn-cancel float-left w-100">Not Now</button>
          </div>
          <div class="col-md-7 mt-3 pr-0">
            <button @click="refreshApp" class="btn-default w-100">refresh now</button>
          </div>
        </div>
      </div>
    </div>
    <div v-for="error in errors" :key="error.date.toString()" class="toast-mask" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast refreshtoast">
        <div class="modal-header update-header pb-0">
          <div class="header row col-md-12 pl-0">
            <div class="col-md-12">{{ error.title || "Error" }}</div>
          </div>
        </div>

        <div class="row col-md-12 pr-0">
          <div class="col-md-8 mt-3" v-if="error.type && error.type === 'access_denied'">
            You do not have sufficient permissions in this subscription. Click on the reload button to logout and select the correct subscription\solution.
            <div v-if="error.message" class="mt-1">{{ error.message }}</div>
          </div>
          <div class="col-md-8 mt-3" v-else>
            An error occurred while processing your request. Click on the refresh button to refresh the application.
            <div v-if="error.message" class="mt-1">{{ error.message }}</div>
          </div>
          <div class="col-md-4 col-alert">
            <img src="./shared/icon-error-large.svg" class="alert-icon" alt="Error" />
          </div>
        </div>
        <div class="d-flex flex-row-reverse pt-1">
          <div class="pl-0" v-if="error.type && error.type === 'access_denied'">
            <button @click="hideError(error)" class="btn-default btn-medium float-right">reload</button>
          </div>
          <div class="pl-0" v-else>
            <button @click="hideError(error)" class="btn-default btn-medium float-right">refresh</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import fullLayout from "@/layouts/full.vue";
import noSidebarLayout from "@/layouts/no-sidebar.vue";
import emptyLayout from "@/layouts/empty.vue";
import noLayout from "@/layouts/nolayout.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import utils from "@/shared/utils";
import Highcharts from "highcharts";
import highchartsBorderRadius from "highcharts-border-radius";
import patternFillInit from "highcharts/modules/pattern-fill";
import { useShared } from "@/stores/shared/store";

// "highcharts-border-radius" is needed to allow specific corner radius settings on chart bars - e.g.: 'borderRadiusTopLeft'
highchartsBorderRadius(Highcharts);
// "pattern-fill" needed to enabled pattern fill on chart bars - e.g.: conditional striped bars
patternFillInit(Highcharts);

// @ts-ignore
// import router from "./router";
import { IUser, IUserTeam } from "@/shared/user";

import "@enlyft/design-system-v2/src/components/assets/styles/main.scss";

library.add(fas);

let cookieDomain = "";

switch (process.env.VUE_APP_MODE) {
  case "stage":
    cookieDomain = "black-dust-5551.idatalabs.com";
    break;
  case "testing1":
    cookieDomain = "misty-dream-2423.idatalabs.com";
    break;
  case "preproduction":
    cookieDomain = "raspy-wood-1197.idatalabs.com";
    break;
  case "production":
    cookieDomain = "enlyft.com";
    break;
  case "testing1":
    cookieDomain = "misty-dream-2423.idatalabs.com";
    break;
  case "testing1_upgrade":
    cookieDomain = "green-wood-9340.enlyft.com";
    break;
  default:
    cookieDomain = "";
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let teamMutation: () => void;

export default {
  name: "app",
  components: { noLayout, emptyLayout, noSidebarLayout, fullLayout },
  data(): {
    defaultLayout: string;
    errors: any[];
    allErrors: any[];
    serviceWorker: any;
    dateCreated: number;
    buildNumber: string | null;
    needHardRefresh: boolean;
  } {
    return {
      defaultLayout: "empty",
      errors: [],
      allErrors: [],
      serviceWorker: null,
      dateCreated: new Date().getTime(),
      buildNumber: null,
      needHardRefresh: false,
    };
  },
  created() {
    // while we use persisted state to local storage
    this.$store.dispatch("resetUpdateChecks");
    let vm = this as any;
    const cookies: any = $cookies;

    if (cookies) {
      cookies.config("1d", "/", cookieDomain, true);

      // Get team id before jwt to use this team id as key to get the jwt
      // If no team id is set then get the jwt that has no team id and redirect user to team selection page
      // Tries to get from store first, which is set on sessionStorage, so is tab-wise.
      const activeTeamId = this.$store.getters["team/activeTeamId"] ? this.$store.getters["team/activeTeamId"] : cookies ? cookies.get("teamid") : undefined;

      // We need to use FE set cookies (instead of http-only) for the jwt to be bale to share it with browser extension
      const jwt = activeTeamId ? cookies.get(`jwt-team-${activeTeamId}`) : undefined;

      const user = cookies.get("loggedinuser");

      // this token does not have team id, so it is only supposed to be used to get user's teams list
      const jwtAuth = cookies.get("jwt-auth");

      let activeModelId = cookies ? cookies.get("modelId") : undefined;
      // check both lower and camel cases to make sure
      const modelIdFromQuery = new URLSearchParams(window.location.search).get("modelId");
      // if model id from query is different, sets it as active and on cookies
      if (modelIdFromQuery && modelIdFromQuery !== activeModelId) {
        activeModelId = modelIdFromQuery;
        cookies.set("modelId", activeModelId);
      }

      if (!localStorage.getItem(`jwt-team-${activeTeamId}`) || !vm.userTeams) {
        if (jwt && activeTeamId) {
          utils.setStorage(`jwt-team-${activeTeamId}`, jwt);
        } else if (jwtAuth) {
          utils.setStorage("jwt", jwtAuth);
        }
      }

      setTimeout(() => {
        if (vm.loggedInUser && vm.userTeams && Array.isArray(vm.userTeams) && vm.userTeams.length > 0 && vm.userTeams[0].plan_id && vm.userTeams[0].payment_status) {
          this.setActiveTeamOffering(vm.userTeams, vm.loggedInUser, activeTeamId, activeModelId);
        } else if (jwtAuth && user && user.id && user.api_key) {
          vm.getJWT({ accountId: user.id, apiKey: user.api_key })
            .then(() => {
              vm.getUserTeams({
                accountId: user.id,
                setUser: true,
                apiKey: user.api_key,
              })
                .then((teams: IUserTeam[]) => {
                  vm.setuserTeams(teams);
                  this.setActiveTeamOffering(teams, user, activeTeamId, activeModelId);
                })
                .catch((err: any) => {
                  console.error("error: Error getting teams", err);
                  this.$store.dispatch("setAppNeedLogin", true);
                });
            })
            .catch((err: any) => {
              console.error("error: Error getting subscriptions", err);
              this.$store.dispatch("setAppNeedLogin", true);
            });
        } else if (user && !user.id) {
          console.error("error: User ID not found");
          this.$store.dispatch("setAppNeedLogin", true);
        } else {
          console.info("All session info available for the new session");
          this.$store.dispatch("setAppReady");
        }
      }, 250);
    } else {
      console.info("no cookies?");
      this.$store.dispatch("setAppReady");
    }

    document.addEventListener("swUpdated", (e: any) => {
      this.serviceWorker = e.detail;
    });

    document.addEventListener("swUpdateAvailable", (e: any) => {
      this.serviceWorker = e.detail;
    });

    if (typeof navigator.serviceWorker !== "undefined") {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // console.info("new version loaded", e);
      });

      navigator.serviceWorker.addEventListener("message", (e: any) => {
        if (e.data && e.data.version && e.data.version !== utils.BUILD_VERSION && e.data.version !== this.buildNumber && e.data.version.length > 10) {
          this.buildNumber = e.data.version;
          const diff: number = new Date().getTime() - this.dateCreated;
          if (diff < 10000) {
            console.info("new version available, auto refresh ", diff / 1000.0, "s");
            window.location.reload();
            return;
          }
          setTimeout(() => {
            this.$store.dispatch("setUpdateExist");
            if (e.data.forceRefresh) {
              this.$store.dispatch("setNeedHardRefresh");
            }
          }, 20000);
        } else {
          this.$store.dispatch("resetUpdateErrorRefresh");
        }
      });
    }

    if ((this as any).loggedInUser) {
      let team = (this as any).activeTeam ? (this as any).activeTeam : null;

      try {
        utils.setUserProperties((this as any).loggedInUser, team);
        utils.startGoogleAnalytics((this as any).loggedInUser, team);
        utils.setMixPanelUserProperties((this as any).loggedInUser, team, this.activeModel, this.userTeams);

        if (window && window.location && window.location.href) {
          const utmParams = utils.getUTMParams(window.location.href);
          utils.setMixpanelUTMProperties(utmParams, (this as any).loggedInUser.id);
          this.$store.dispatch("setUtmData", utmParams);
        }
      } catch (e) {
        console.error("Error updating user/active team for analytics." + e);
      }
    }
  },
  mounted() {
    teamMutation = this.$store.subscribe((mutation: any, state: any) => {
      switch (mutation.type) {
        case "team/ActiveTeam":
          if (state.team.activeTeam) {
            let currentUser = state.login.user;
            let currentTeam = state.team.activeTeam;
            try {
              utils.setUserProperties(currentUser, currentTeam);
              utils.updateBeamer(currentUser, currentTeam);
              utils.setMixPanelUserProperties(currentUser, currentTeam, state.model.activeModel, state.login.teams);
            } catch (e) {
              console.error("Error updating user/active team for analytics." + e);
            }
          }
          return;
        case "shared/AddError":
          if (state.shared.errors.length > 0) {
            this.allErrors = state.shared.errors;
            this.showError(state.shared.errors[state.shared.errors.length - 1]);
          }
          return;
        default:
          break;
      }
    });
  },
  computed: {
    ...mapStores(useShared),
    versionNumber(): string | null {
      if (!this.buildNumber) {
        return null;
      }
      return "v1." + this.buildNumber.substr(3, 6);
    },
    layout(): string {
      return (this.$route.meta && (this.$route.meta.layout || this.defaultLayout)) + "-layout";
    },
    ...mapGetters("login", {
      loggedInUser: "getUser",
    }),
    ...mapGetters("team", {
      teamName: "activeTeamName",
      activeTeam: "activeTeam",
    }),
    ...mapGetters("model", {
      activeModel: "activeModel",
    }),
    ...mapState("login", {
      userTeams: "teams",
    }),
  },
  methods: {
    ...mapActions({
      getUserTeams: "login/getUserTeams",
      setActiveTeam: "team/setActiveTeam",
      setuserTeams: "login/setuserTeams",
      getJWT: "login/getJWT",
      getTeamModels: "model/getTeamModels",
      setActiveModel: "model/setActiveModel",
      getTeamFeatures: "team/getTeamFeatures",
      setTeamFeatures: "team/setTeamFeatures",
    }),

    showError(error: any) {
      this.errors.push(error);
      setTimeout(() => {
        this.hideError(error);
      }, 500000);
    },
    hideError(error: any) {
      let recentErrors: object[] = [];
      if (error && error.date) {
        try {
          const five_min = 5 * 60 * 1000;
          this.allErrors.forEach(function (err: any) {
            if (Date.parse(error.date) - Date.parse(err.date) < five_min && error.type === err.type) {
              recentErrors.push(err);
            }
          });
        } catch (err) {
          console.error("Error checking date ", err);
        }
      }
      this.errors = [];
      if (error && error.type && (error.type === "access_denied" || this.$route.name === "subscription" || this.$route.name === "stripe")) {
        try {
          let errorData = {
            type: error.type,
            message: error.message,
          };
          utils.trackIntercomEvent("access_denied", errorData);
        } catch (e) {
          console.error("Unknown intercom failure" + e);
        }
        this.$store.dispatch("login/logout").then(() => {
          this.$router.push({ name: "loginEmail" });
        });
      } else if (recentErrors && recentErrors.length > 1) {
        try {
          let metadata = {
            type: error.type,
            message: error.message,
          };
          utils.trackIntercomEvent("multiple-errors", metadata);
        } catch (e) {
          console.error("Unknown intercom failure" + e);
        }
        this.$router.push({ name: "error" });
      } else {
        window.location.reload();
      }
    },
    updateUser() {
      utils.setUserProperties((this as any).loggedInUser, (this as any).activeTeam);
    },
    dontRefresh() {
      this.$store.dispatch("setNeedRefreshBar");
    },
    refreshApp() {
      this.$store.dispatch("refreshApp");
    },
    setActiveTeamOffering(teams: IUserTeam[], user: IUser, activeTeam?: string, activeModel?: string) {
      let vm = this as any;
      if (teams && Array.isArray(teams)) {
        if (!(this as any).loggedInUser) {
          console.error("Error: User not found in store. Need Login.");
          this.$store.dispatch("setAppNeedLogin", true);
          return;
        }
        vm.setuserTeams(teams);
        if (teams.length === 1) {
          const team = teams[0];
          try {
            vm.setActiveTeamOfferingHelper(team, activeModel);
          } catch (err) {
            console.error("error: Error setting active team/model", err);
            this.$store.dispatch("setAppNeedLogin", true);
          }
          return;
        } else if (activeTeam) {
          const found = teams.find((t) => t.account_id === activeTeam);
          if (found) {
            try {
              vm.setActiveTeamOfferingHelper(found, activeModel);
            } catch (err) {
              console.error("error: Error getting offerings", err);
              this.$store.dispatch("setAppNeedLogin", true);
            }
            return;
          } else {
            this.$store.dispatch("setAppReady");
          }
        } else {
          this.$store.dispatch("setAppReady");
        }
      } else {
        this.$store.dispatch("setAppReady");
      }
    },
    setActiveTeamOfferingHelper(team: any, modelId: string) {
      let vm = this as any;
      if (team.account_id) {
        this.getTeamFeatures(team.account_id).then((data: any) => {
          this.setTeamFeatures(data);
          vm.setActiveTeam(team);
          vm.setActiveModelIfBelongsToTeam(modelId);
          this.$store.dispatch("setAppReady");
        });
      }
    },
    async setActiveModelIfBelongsToTeam(modelId?: string) {
      try {
        const models = await this.getTeamModels();
        if (models && Array.isArray(models)) {
          if (models.length === 1) {
            // if there is only one model it must be set even if received modelId is different
            this.setActiveModel(models[0]);
          } else if (modelId && modelId != "null") {
            // if there is more than one model, only sets if the modelId received matches one of the team models
            const modelFound = models.find((model) => model._id === modelId);
            if (modelFound) {
              this.setActiveModel(modelFound);
            }
            // if no model is found to set router will set the first option
          }
        }
      } catch (err: any) {
        console.error("error: teamSelection subscribe in login getTeamModels", err);
        this.$store.dispatch("setAppNeedLogin", true);
      }
    },
  },
  watch: {
    loggedInUser(val) {
      if (val) {
        try {
          utils.setUserProperties((this as any).val, (this as any).activeTeam);
          utils.setMixPanelUserProperties((this as any).val, (this as any).activeTeam, this.activeModel, this.userTeams);
        } catch (e) {
          console.error("Unknown APM failure " + e);
        }
      }
    },
    activeTeam(val) {
      if (val) {
        try {
          utils.setUserProperties((this as any).loggedInUser, val);
          utils.setMixPanelUserProperties((this as any).loggedInUser, (this as any).val, this.activeModel, this.userTeams);
        } catch (e) {
          console.error("Unknown analytics failure " + e);
        }
      }
    },
    "$route.name"(val) {
      document.body.setAttribute("data-route", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.toast {
  animation: 0.2s ease-out 0s 1 slideInFromBottom;
  opacity: 1;
  margin: 0 auto 10px auto;
  background-color: #fff;
  position: absolute;
  top: 30%;
  left: 30%;
  min-width: 600px;
  padding: 3%;
  color: #4f5f71 !important;
}
.toast-header {
  padding: 5px 10px;
}
.refreshtoast .toast-header {
  color: #333;
}
.refreshbutton {
  margin: 1rem 0;
}
.norefreshbutton {
  float: right;
  text-transform: none;
  font-size: 0.85em;
  margin-bottom: 1rem;
}
#toasts .toast .close {
  cursor: pointer;
}
.update-header {
  border-bottom: none !important;
}
.col-alert {
  margin-top: -15px;
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>

<style lang="scss">
:root {
  --container-max-width: 1032px;
  --container-padding-y: 16px;
}

@mixin fontdef($FontPath, $FontVersion, $FontSubFolder: "regular", $FontType: "Regular") {
  src: url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.eot?v=#{$FontVersion}");
  src:
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.eot?#iefix&v=#{$FontVersion}") format("embedded-opentype"),
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.woff?v=#{$FontVersion}") format("woff"),
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.ttf?v=#{$FontVersion}") format("truetype"),
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.svg?v=#{$FontVersion}##{$FontType}") format("svg");
}

@import "assets/styles/custom";

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.app-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.refreshNotice {
  font-size: 0.8rem;
  position: fixed;
  bottom: 3rem;
  background: #fff;
  left: 1rem;
  z-index: 300;
  padding: 0.25rem 1rem 0.5rem 1rem;
  box-shadow: 0px 0px 5px #ccc;
}

.narrowSideBar-header {
  padding-left: var(--sidebar-narrow) !important;
}
/** This div needs to be "visible" for screen readers to read it. */
#aria-live--content {
  height: 1px;
  overflow: hidden;
  width: 1px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
