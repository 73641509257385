export enum Rights {
  Admin = 1,
  Editor,
  Sales,
  Viewer,
  EnlyftAdmin,
  TeamEdit,
  SeeSegmentTab,
  SeeEngageTab,
  SeeSettings,
  SeeMeasureTab,
  SeeDiscoverTab,
  SeeAllSegments,
  SeeMySegments,
  SeeTaggedAccounts,
  SeeRecentAccounts,
  SeeInsightsTab,
  SeeSetupTab,
  CanDownloadSearch,
  ChangeModel,
  EnableCRM,
  DeleteSegment,
  EditSegment,
  FreemiumUser,
  OldSalesforce,
  Capabilities,
  VisitorTracker,
  RedeemAccounts,
  SuppressAccounts,
  RedeemIndividualAccounts,
  AddHAG,
  AddLinkedIn,
  AddIntent,
  EnrichSuppressAccounts,
  AccountRedemptionBulk,
  AccountRedemptionIndividual,
  SeeSavedAccounts,
  SaveContacts,
  EnableAddOn,
  ShowPayment,
  EnableEbsIntensity,
  IcpFitScore,
  DashboardPage,
  SolutionPivot,
  SolutionAndOutreach,
  ChatGptEmailer,
  GenAiLanguage,
}

export interface IAccessPlan {
  is_company_aggregate_access?: string;
  is_download_samples?: string;
  is_mui?: string;
  is_payment_flow?: string;
  [key: string]: any;
}

export interface IUser {
  access_plan?: IAccessPlan;
  address?: string;
  api_key?: string;
  creation_time?: string;
  credits?: number;
  email?: string;
  id?: string;
  last_seen?: string;
  name?: string;
  organization?: string;
  phone?: string;
  role?: string;
  [key: string]: any;

  is?(r: Rights, team?: IUserTeam): boolean;
  can?(r: Rights, team?: IUserTeam): boolean;
  canFeature?(r: Rights, featuresLimit?: object): boolean;
}

export interface IUserTeam {
  account_flags?: object;
  account_id: string;
  account_name: string;
  creation_time: string;
  is_deleted: string;
  last_updated: string;
  role: string;
  /** Includes Launch Darkly flags */
  user_account_flags?: userAccountFlags;
  user_email: string;
  user_id: string;
  user_name: string;
  views_remaining: number;
  organization_id: string;
  is_enlyft_admin: boolean;
  payment_status: string;
  plan_id: string;
  plan_internal_name?: string;
  plan_name?: string;
  is_trial?: boolean;
  featuresLimit?: any;
}

export interface userAccountFlags {
  is_crm_enabled?: boolean;
  [key: string]: any;
}

export class User implements IUser {
  access_plan?: IAccessPlan = {};
  user_account_flags?: userAccountFlags = {};

  address?: undefined;
  api_key?: undefined;
  credits?: undefined;
  creation_time?: undefined;
  email?: undefined;
  id: undefined;
  role?: undefined; // admin | webuser
  last_seen?: undefined;
  name?: undefined;
  organization?: undefined;
  phone?: undefined;
  is_enlyft_admin?: undefined;
  job_title?: undefined;

  constructor(raw: IUser) {
    Object.assign(this, raw);
  }

  is(r: Rights, team?: IUserTeam): boolean {
    switch (r) {
      case Rights.Sales:
        return !!(team && team.role === "sales");
      case Rights.Viewer:
        return !!(team && team.role === "viewer");
      case Rights.Admin:
        return !!(team && (team.role === "admin" || team.role === "enlyft_cs"));

      case Rights.EnlyftAdmin:
        return !!(this.is_enlyft_admin && this.is_enlyft_admin === true);
    }
    return false;
  }

  can(r: Rights, team?: IUserTeam): boolean {
    // console.error("check rights", Rights[r], team, this);
    if (this.role === "admin" || this.role === "enlyft_cs") {
      return true;
    }
    // todo: where do we store / check for those rights
    switch (r) {
      case Rights.TeamEdit:
        return !!(team && (team.role === "admin" || team.role === "enlyft_cs"));

      case Rights.SeeEngageTab:
      case Rights.SeeSettings:
      case Rights.SeeTaggedAccounts:
      case Rights.SeeRecentAccounts:
      case Rights.SeeSavedAccounts:
      case Rights.SeeMySegments:
        if (team && team.plan_internal_name && team.plan_internal_name !== "free-plan") {
          return true;
        }
      case Rights.SeeMeasureTab:
      case Rights.SeeInsightsTab:
      case Rights.CanDownloadSearch:
      case Rights.SeeAllSegments:
        if (team && team.role !== "sales" && team.plan_internal_name && team.plan_internal_name !== "free-plan") {
          return true;
        }
        return false;
      case Rights.DeleteSegment:
      case Rights.EditSegment:
      case Rights.SeeSegmentTab:
      case Rights.SeeDiscoverTab:
        if (team && team.role !== "sales" && team.plan_internal_name && team.plan_internal_name !== "free-plan") {
          return true;
        }
        if (team && team.plan_internal_name && team.plan_internal_name !== "free-plan" && team.role === "sales") {
          return true;
        }
        return false;
      case Rights.SeeSetupTab:
        if (team && team.role !== "sales" && team.plan_internal_name && team.plan_internal_name !== "free-plan") {
          return true;
        }
        return false;

      case Rights.ChangeModel:
        if (team && team.role !== "sales" && team.plan_internal_name && team.plan_internal_name !== "free-plan") {
          return true;
        }
        return false;
      case Rights.AddIntent:
      case Rights.AddLinkedIn:
      case Rights.AddHAG:
      case Rights.EnrichSuppressAccounts:
        if (team && (team.role === "admin" || team.role === "enlyft_cs") && team.plan_internal_name && team.plan_internal_name !== "free-plan") {
          return true;
        }
        if (team && team.plan_internal_name && team.plan_internal_name !== "free-plan" && team.role !== "sales") {
          return true;
        }
        return false;

      case Rights.OldSalesforce:
        return !!(team && team.user_account_flags && team.user_account_flags.old_salesforce);

      case Rights.FreemiumUser:
        return !!(team && team.plan_name && team.plan_internal_name === "free-plan");

      case Rights.VisitorTracker:
        return !!(team && team.user_account_flags && team.user_account_flags.vistrac_enabled);

      case Rights.RedeemAccounts:
        return !!(team && team.user_account_flags && (team.role === "admin" || team.role === "viewer" || team.role === "enlyft_cs" || this.is_enlyft_admin === true));

      case Rights.RedeemIndividualAccounts:
        if (team && team.plan_internal_name && team.plan_internal_name !== "free-plan" && team.plan_internal_name !== "starter") {
          return true;
        }
        return false;

      case Rights.ShowPayment:
        return !!(team && team.hasOwnProperty("payment_status") && team.payment_status === "new" && !team.is_trial);

      default:
        return false;
    }
  }

  canFeature(r: Rights, featuresLimit?: any): boolean {
    switch (r) {
      // NOTE: the feature account_redemption_bulk is to check if a plan allows to bulk save, not the user. For user permission check the user role on team/subscription
      case Rights.AccountRedemptionBulk:
        return !!(featuresLimit && featuresLimit.account_redemption_bulk && featuresLimit.account_redemption_bulk > 0);
      case Rights.AccountRedemptionIndividual:
        return !!(featuresLimit && featuresLimit.account_redemption_individual && featuresLimit.account_redemption_individual > 0);
      case Rights.EnableCRM:
        return !!(featuresLimit && featuresLimit.is_crm_allowed && featuresLimit.is_crm_allowed > 0);
      case Rights.SaveContacts:
        return !!(featuresLimit && featuresLimit.save_contacts && featuresLimit.save_contacts > 0);
      case Rights.EnableAddOn:
        return !!(featuresLimit && featuresLimit.addon_enabled && featuresLimit.addon_enabled > 0);
      case Rights.SuppressAccounts:
        return !!(featuresLimit && featuresLimit.upload_suppress_accounts && featuresLimit.upload_suppress_accounts > 0);
      case Rights.EnableEbsIntensity:
        return !!(featuresLimit && featuresLimit.ebs_intensity && featuresLimit.ebs_intensity > 0);
      case Rights.IcpFitScore:
        return !!(featuresLimit && featuresLimit.icp_fit_score && featuresLimit.icp_fit_score > 0);
      case Rights.DashboardPage:
        return !!(featuresLimit && featuresLimit.dashboard_page && featuresLimit.dashboard_page > 0);
      case Rights.SolutionPivot:
        return !!(featuresLimit && featuresLimit.solution_pivot && featuresLimit.solution_pivot > 0);
      case Rights.SolutionAndOutreach:
        return !!(featuresLimit && featuresLimit.solution_and_outreach && featuresLimit.solution_and_outreach > 0);
      case Rights.ChatGptEmailer:
        return !!(featuresLimit && featuresLimit.chatgpt_emailer && featuresLimit.chatgpt_emailer > 0);
      case Rights.GenAiLanguage:
        return !!(featuresLimit && featuresLimit.gen_ai_language && featuresLimit.gen_ai_language > 0);
      default:
        return false;
    }
  }
}
